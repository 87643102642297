import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../../index.jsx';
import '../../css/pages/treatments/treatments.css';
import env from '../../js/public'
class CosmicDentistry extends React.Component {
	constructor(props) {
		super();
		this.state = {};
	}
	componentDidMount() {
		this.props.dispatch(storeStateInfo('treatments'));
	}
	render() {
		return (<div className="cosmicDentistry">
			<div className="row cosmic_row">
				<div className="col-sm-4 title"> {/*col-sm-4 */}
					<span className="cosmic_heading">Cosmetic Dentistry</span>
					<img className="underline"
						src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
						alt=""
					/>
					{/* <img className="img_1" src="http://localhost:3000/assets/images/woman.jpg" alt=""></img> */}
				</div>
				<div className="col-sm-8"> {/*col-sm-8*/}
					<p className=""><strong>Increase your sense of confidence and self esteem with Cosmetic
					Dental Surgery and Treatment for Smile makeover!!</strong>
					</p>

					<p>
						A smile makes life more beautiful but crooked, stained or missing teeth can
						lead people to not smiling at all as they are embarrassed of what others will
						think. Such dental imperfections really affect a person’s self esteem.
					</p>
					<p>
						Cosmetic Dentistry can easily correct these flaws and improve your
						appearance so that you have a smile that you are proud to show
						off. iDentist is one of the <b>best cosmetic dental clinics in Indore, India.</b>
						We use the latest innovations and cosmetic techniques to provide the finest
						aesthetic dentistry available in the world today.
					</p>
					<p>
						We are dedicated to create smiles that are beautiful, natural, healthy, long
						lasting and comfortable. Our Cosmetic Dentists use a comprehensive
						approach known as Smile Design to give you a perfect smile. They perform a
						detailed evaluation of your teeth and design a smile that is best customized to your face.
						Many aesthetic tooth flaws like stained teeth, chipped, cracked,
						crooked or rotated teeth and teeth with gaps can be corrected with cosmetic
						dentistry.
					</p>
					<p>
						The cosmetic dental procedures consist of a variety of treatments. You might need one or a combination of treatments.
					</p>
				</div>
			</div>
			<div className="cosmic_h1">
				<h1>Types of Cosmetic Dentistry</h1>
				<img
					className=""
					src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
					alt=""
				/>
			</div>
			<div className="cdGridContainer">
				<div className="cdGridItem">
					<div className="cdGridInnerContainer">
						<div className="categoryImage">
							<img src={env.string +'teeth-whitening.jpg'} alt=""></img>
						</div>
						<div className="content">
							<div className="cosmicTitle">
								<img
									className="cosmic_icon"
									src="http://clients.technooyster.biz/smilestudio-dev/wp-content/uploads/2019/04/teeth1.png"
									alt=""
								/>
								<span className="cosmic_text"><strong>Teeth Whitening</strong></span>
							</div>
							<img
								className="cosmic_line"
								src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
								alt=""
							/>
							<p>
								A whiter and brighter smile is now within your reach with teeth whitening. It is
								one of the most popular cosmetic treatments. It lightens teeth, removes stains
								and discolorations giving you pearly white teeth. We offer our patients the
								flexibility to choose between getting treated in our clinic or at-home. The
								treatment in the clinics can whiten your teeth up to 4 shades light in less than
								an hour.
					</p>
						</div>
					</div>
				</div>

				<div className="cdGridItem"> {/*col-sm-4*/}
					<div className="cdGridInnerContainer">
						<div className="categoryImage">
							<img src={env.string +'porcelain-veneers-1.jpg'} alt=""></img>
						</div>
						<div className="content">
							<div className="cosmicTitle">
								<img
									className="cosmic_icon"
									src="http://clients.technooyster.biz/smilestudio-dev/wp-content/uploads/2019/04/teeth1.png"
									alt=""
								/>
								<span className="cosmic_text"><strong>Porcelain Veneers</strong></span>
							</div>
							<img
								className="cosmic_line"
								src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
								alt=""
							/>

							<p>
								Veneers are extremely thin shells made of translucent porcelain that can be
								bonded to your teeth. This is an excellent option to cover a chipped or cracked tooth or
								close gaps between your teeth. They are strong, durable
								and a non-invasive way of transforming your smile.
							</p>
						</div>
					</div>
				</div>
				<div className="cdGridItem"> {/*col-sm-4*/}
					<div className="cdGridInnerContainer">
						<div className="categoryImage">
							<img src={env.string +'webwbw-min.jpg'} alt=""></img>
						</div>
						<div className="content">
							<div className="cosmicTitle">
								<img
									className="cosmic_icon"
									src="http://clients.technooyster.biz/smilestudio-dev/wp-content/uploads/2019/04/teeth1.png"
									alt=""
								/>
								<span className="cosmic_text"><strong>Cosmetic Dental Bonding</strong></span>
							</div>
							<img
								className="cosmic_line"
								src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
								alt=""
							/>
							<p>
								Our cosmetic dentists apply a thin layer of a tooth- coloured resin composite
								material to your teeth, instantly improving its appearance. It needs little
								or absolutely no tooth preparation and thus preserves the natural tooth. Dental
								bonding is more affordable and rapidly changes the contour of your teeth in a
								single sitting.
						</p>
						</div>
					</div>
				</div>
				<div className="cdGridItem"> {/*col-sm-4*/}
					<div className="cdGridInnerContainer">
						<div className="categoryImage">
							<img src={env.string +'what-is-invisalign.jpg'} alt=""></img>
						</div>
						<div className="content">
							<div className="cosmicTitle">
								<img
									className="cosmic_icon"
									src="http://clients.technooyster.biz/smilestudio-dev/wp-content/uploads/2019/04/teeth1.png"
									alt=""
								/>
								<span className="cosmic_text"><strong>Invisible braces</strong></span>
							</div>
							<img
								className="cosmic_line"
								src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
								alt=""
							/>
							<p>
								Invisalign is a type of orthodontic treatment that helps to straighten teeth
								without metal brackets and wires that are uncomfortable and unattractive.
								It is a clear alternative to metal braces for adults and teens. It consists of a series
								of aligners that are virtually invisible and completely removable. Unlike
								traditional braces, with Invisalign you can eat, drink, brush with comfort.
								Ceramic braces and lingual braces are other types of aesthetic orthodontic
								options.
					</p>
						</div>
					</div>
				</div>
				<div className="cdGridItem"> {/*col-sm-4*/}
					<div className="cdGridInnerContainer">
						<div className="categoryImage">
							<img src={env.string +'ceramic.jpg'} alt=""></img>
						</div>
						<div className="content">
							<div className="cosmicTitle">
								<img
									className="cosmic_icon"
									src="http://clients.technooyster.biz/smilestudio-dev/wp-content/uploads/2019/04/teeth1.png"
									alt=""
								/>
								<span className="cosmic_text"><strong>Ceramic Dental Crowns</strong></span>
							</div>
							<img
								className="cosmic_line"
								src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
								alt=""
							/>
							<p>
								They are natural looking restorations used when the entire tooth needs to be
								covered. The ceramic crowns are very strong, stain resistant and can
								rejuvenate your smile.
					</p>
							<p>
								The cost of Cosmetic dental procedure and surgery varies. Get in touch with
								us today to know which cosmetic dental procedure is right for you.
					</p>
						</div>
					</div>
				</div>
			</div>
		</div>);
	}
}

export default connect()(CosmicDentistry);
