import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../../index.jsx';
import { Link } from 'react-router-dom';
import env from '../../js/public'
import '../../css/pages/treatments/treatments.css';


class MouthRehab extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('treatments'));
    }
    render() {
        return (
            <div>
                <div className="row cosmic_row">
                    <div className="col-sm-4 title"> {/*col-sm-4 */}
                        <span className="cosmic_heading">Full Mouth Rehabilitation</span>
                        <img className="underline"
                            src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                            alt=""
                        />
                        {/* <img className="img_1" src="http://localhost:3000/assets/images/woman.jpg" alt=""></img> */}
                    </div>
                    <div className="col-sm-8"> {/*col-sm-8*/}
                        <p>
                            Restore your smile to its full health and beauty.
                        </p>
                        <p>
                            There are times when a person’s teeth are severely compromised (decayed
                            teeth, gum infection, loss of teeth, worn out teeth, collapsed bite, etc.) and
                            they are unable to eat properly which in turn undermine the overall health.
                        </p>
                        <p>
                            Full mouth rehabilitation, also known as Full mouth reconstruction, is
                            required to rejuvenate the oral health, self-confidence and overall well being.
                            With Full mouth rehabilitation, patients can get a new life with a completely
                            new and fully functional, natural looking teeth.
                        </p>
                        <p>
                            Full mouth rehabilitation is a combination of various treatments like Dental
                            Implants, Gum treatment, Restorative dentistry (crowns/bridges), Smile
                            correction, Root canal treatment or Cavity filling for decayed teeth
                            , Extraction of teeth which cannot be salvaged, etc.
                        </p>
                    </div>
                </div>
                <div className="cdGridContainer">
                    <div className="image">
                        <img src={env.string +'Center-for-Cosmetic-Dentistry-photo-full.jpg'} alt=""></img>
                    </div>
                    <div className="content">
                        <p>
                            Our specialised team of dentists at <b>iDentist</b> have extensive experience and
                            will rebuild and return the mouth to its full function. Since full mouth
                            rehabilitation includes multiple treatments, it is important that each treatment
                            is addressed by their respective specialist/ dental expert.
                        </p>
                        <ul>
                            <li>Root Canals by Endodontist</li>
                            <li>Extraction by Oral & Maxillofacial Surgeon</li>
                            <li>Gum Treatment by Periodontist</li>
                            <li>Implants by Implantologist</li>
                            <li>Crowns/ Bridges/ Restorative work by Prosthodontist</li>
                        </ul>
                        <p>
                            This teamwork has helped hundreds of people achieve their ideal dental
                            health.
                        </p>
                    </div>
                </div>
                <div className="row methods">
                    <div className="col-md-12">
                        <div className="heading">
                            <p><strong>REHABILITATION METHODS</strong></p>
                            <img
                                className="new_line"
                                src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>
                            At <b>iDentist</b> we ensure that each patient receives the best full mouth
                            rehabilitation which is customized to his or her needs. The various options
                            for a full mouth rehabilitation include
                        </p>
                    </div>
                </div>
                <div className="rehab_row">
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'iStock-543979766-2.jpg'} alt="" />
                        </div>
                        <div className="link">
                            <Link to="/treatments/MouthRehab">Dental Implants</Link>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'teeth_4.png'} alt="" />
                        </div>
                        <div className="link">
                            <Link to="/treatments/MouthRehab">Dental Crowns</Link>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'ada_dental_veneer_1_new.jpg'} alt="" />
                        </div>
                        <div className="link">
                            <Link to="/treatments/MouthRehab">Dental Veneers</Link>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'teeth_8.jpg'} alt="" />
                        </div>
                        <div className="link">
                            <Link to="/treatments/MouthRehab">Dental Bridges</Link>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'teeth_6.png'} alt="" />
                        </div>
                        <div className="link">
                            <Link to="/treatments/MouthRehab">Dentures</Link>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'teeth_7.png'} alt="" />
                        </div>
                        <div className="link">
                            <Link to="/treatments/MouthRehab">Root Canal</Link>
                        </div>
                    </div>
                </div>
                <div className="row methods">
                    <div className="col-md-12">
                        <div className="heading">
                            <p><strong>STEPS IN FULL MOUTH REHABILITATION</strong></p>
                            <img
                                className="new_line"
                                src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>
                            Our dentists will provide the patient with the complete understanding of
                            the <Link to="/treatments/GeneralDentistry">dental treatment</Link> and what to anticipate beforehand. This helps to alleviate
                            any fears and prepares the patients in advance. Our caring and dedicated
                            team of dentists and staff will provide you with the best service in a warm
                            and relaxing environment.
                        </p>
                        <p>
                            Start a new life with Full Mouth Rehabilitation.
                        </p>
                        <img src={env.string +'3-Broome-before-after-4.jpg'} alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(MouthRehab);
