/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks*/
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { storeStateInfo } from '../index.jsx';
import env from '../js/public';
const DisplayHeader = () => {
	const [header, setHeader] = useState({ location: '/' });
	const location = useSelector(storeStateInfo);
	if (header.location && location.state_info.state_info) {
		setHeader({ location: location.state_info.state_info });
		location.state_info = {};
	}
	return (
		<header id="header">
			<div className="top-bar">
				<div className="container">
					<div className="row">
						<div className="col-sm-4 col-xs-12">
							<div className="">
								{/* <ul className="social-share">
									<li>
										<a href="">
											<FontAwesomeIcon icon={['fab', 'facebook']} />
										</a>
									</li>
									<li>
										<a href="">
											<FontAwesomeIcon icon={['fab', 'twitter']} />
										</a>
									</li>
									<li>
										<a href="">
											<FontAwesomeIcon icon={['fab', 'linkedin']} />
										</a>
									</li>
									<li>
										<a href="">
											<FontAwesomeIcon icon={['fab', 'dribbble']} />
										</a>
									</li>
									<li>
										<a href="">
											<FontAwesomeIcon icon={['fab', 'skype']} />
										</a>
									</li>
								</ul> */}
								{/* <div className="search">
									<form>
										<input
											type="text"
											className="search-form"
											autoComplete="off"
											placeholder="Search"
										/>
										<FontAwesomeIcon icon={[ 'fa', 'search' ]} style={{ color: 'white' }} />
									</form>
								</div> */}
							</div>
						</div>
						<div className="col-sm-8 col-xs-12">
							<div className="top-number">
								<a className="mail headerLink" href="mailto:ankitmhaskar07@yahoo.co.in.com">
									<FontAwesomeIcon icon={['fa', 'envelope']} /> ankitmhaskar07@yahoo.co.in
								</a>
								<a className="mail headerLink mail_space" href="mailto:snehal_viv@yahoo.co.in">
									<FontAwesomeIcon icon={['fa', 'envelope']} /> snehal_viv@yahoo.co.in
								</a>
								<Link className="info_icons headerLink" to="/AboutUs">
									<FontAwesomeIcon icon={['fa', 'user']} /> About Us
								</Link>
								<Link className="info_icons headerLink" to="/ContactUs">
									<FontAwesomeIcon icon={['fa', 'phone-square']} /> Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<nav className="navbar navbar-inverse" role="banner">
				<div className="container">
					<div className="navbar-header">
						<button
							type="button"
							className="navbar-toggle"
							data-toggle="collapse"
							data-target=".navbar-collapse"
						>
							<span className="sr-only">Toggle navigation</span>
							<span className="icon-bar" />
							<span className="icon-bar" />
							<span className="icon-bar" />
						</button>
						<Link className="navbar-brand" to="/">
							<img className="logo_size" src={env.string +'identist_logo.png'} alt="logo" />
						</Link>
					</div>

					<div className="collapse navbar-collapse navbar-right">
						<ul className="nav navbar-nav">
							<li className={header.location == '/' ? 'active' : ''}>
								<Link to="/">Home</Link>
							</li>
							<li className={header.location == 'AboutUs' ? 'active' : ''}>
								<Link to="/AboutUs">About Us</Link>
							</li>
							<li className={header.location == 'Credentials' ? 'active' : ''}>
								<Link to="/Credentials">Credentials</Link>
							</li>
							<li className={header.location == 'treatments' ? 'dropdown active' : 'dropdown'}>
								<a href="#" className="dropdown-toggle" data-toggle="dropdown">
									Treatments We Provide <i className="fa fa-angle-down" />
								</a>
								<ul className="dropdown-menu">
									<li>
										<Link to="/treatments/CosmicDentistry">Cosmic Dentistry</Link>
									</li>
									<li>
										<Link to="/treatments/SurgicalExtraction">Surgical Extraction Of Tooth</Link>
									</li>
									<li>
										<Link to="/treatments/MouthRehab">Full Mouth Rehabilitation</Link>
									</li>
									<li>
										<Link to="/treatments/RootCanal">Root Canal Treatment</Link>
									</li>
									<li>
										<Link to="/treatments/SmileDesign">Smile Designing</Link>
									</li>
									<li>
										<Link to="/treatments/CompositeVeneers">Composite Veneers</Link>
									</li>
									<li>
										<Link to="/treatments/GeneralDentistry">General Dentistry</Link>
									</li>
									<li>
										<Link to="/treatments/Crown">Crown & Bridges</Link>
									</li>
									<li>
										<Link to="/treatments/DentalImplants">Dental Implants</Link>
									</li>
								</ul>
							</li>
							{/* <li className={header.location == 'Specialities' ? 'active' : ''}>
								<Link to="/Specialities">Our Specialities</Link>
							</li> */}
							<li className={header.location == 'PhotoGallery' ? 'active' : ''}>
								<Link to="/SmileGallery">Smile Gallery</Link>
							</li>
							{/* <li className="dropdown">
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                            Pages <i className="fa fa-angle-down" />
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <a href="blog-item.html">Blog Single</a>
                            </li>
                            <li>
                                <a href="pricing.html">Pricing</a>
                            </li>
                            <li>
                                <a href="404.html">404</a>
                            </li>
                        </ul>
                    </li> */}
							<li className={header.location == 'Testimonials' ? 'active' : ''}>
								<Link to="/Testimonials">Testimonials</Link>
							</li>
							<li className={header.location == 'ContactUs' ? 'active' : ''}>
								<Link to="/ContactUs">Contact Us</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	);
};
class Header extends React.Component {
	constructor(props) {
		super();
	}
	render() {
		return <DisplayHeader />;
	}
}
export default connect()(Header);
