import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../index.jsx';
import testimonials from '../json/testimonials.json';
import env from '../js/public';
import '../css/pages/testimonials.css';
import Rating from 'react-rating';

class Testimonial extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('Testimonials'));
        console.log(testimonials);
    }
    render() {
        return (<div className="testimonials">
            <div className="grid">
                {
                    testimonials.data.map(x => {
                        console.log(x);
                        return <div className="grid-item" key={x.id}>
                            <div className="header">
                                <img src={env.string +x.image_url} alt="" />
                                <p className="name">
                                    {x.patient_name}
                                </p>
                                <span>
                                    <Rating
                                        initialRating={x.rating}                                    
                                        stop={x.rating}
                                        emptySymbol={['fa fa-star-o fa-2x low', 'fa fa-star-o fa-2x low',
                                            'fa fa-star-o fa-2x medium', 'fa fa-star-o fa-2x medium',
                                            'fa fa-star-o fa-2x high', 'fa fa-star-o fa-2x high']}
                                        fullSymbol={['fa fa-star fa-2x low', 'fa fa-star fa-2x low',
                                            'fa fa-star fa-2x medium', 'fa fa-star fa-2x medium',
                                            'fa fa-star fa-2x high', 'fa fa-star fa-2x high']}
                                            readonly
                                    />
                                </span>
                            </div>
                            <p>
                                {x.description}
                            </p>
                        </div>
                    })
                }
            </div>
        </div>);
    }
}

export default connect()(Testimonial);