import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../../index.jsx';
import { Link } from 'react-router-dom';
import env from '../../js/public'
import '../../css/pages/treatments/treatments.css';

class SurgicalExtraction extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('treatments'));
    }
    render() {
        return (<div className="surgicalExtraction">
            <div className="row extract_row">
                <div className="col-sm-4 title"> {/*col-sm-4 col-md-6*/}
                    <span className="extract_heading">Surgical Extraction Of Tooth</span>
                    <img
                        className="new_line"
                        src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                        alt=""
                    />
                    {/* <img src="http://localhost:3000/assets/images/impact.webp" alt=""></img> */}
                </div>
                <div className="col-sm-8"> {/*col-sm-8 col-md-6 col-lg-5*/}
                    <p>
                        At <b>iDentist</b>, we take every effort to preserve your natural teeth. But there
                        are some instances when tooth extraction is the only option. When a tooth
                        cannot be saved, it must be professionally removed through a procedure
                        called tooth extraction.
                    </p>
                    <span><strong>Reasons for Tooth Extraction :</strong></span>
                    <ul>
                        <li>
                            Severely decayed or infected teeth which cannot be treated by root canal
                     </li>
                        <li>
                            To make space for <Link to="/treatments/CosmicDentistry">orthodontic/ braces treatment</Link>
                        </li>
                        <li>
                            Badly positioned teeth
                        </li>
                        <li>
                            Broken teeth which cannot be restored
                        </li>
                        <li>
                            Advanced gum disease that makes teeth loose
                        </li>
                        <li>
                            Partially erupted or impacted wisdom teeth.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tooth-extraction-blog">
                <img src={env.string +'tooth-extraction-blog-1.jpg'} alt="" />
            </div>
            <div className="extract_h1">
                <h1>Types of Tooth Extractions</h1>
                <img
                    className=""
                    src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                    alt=""
                />
            </div>
            <div className="cdGridContainer">
                <div className="cdGridItem"> {/*col-sm-4*/}
                    <div className="cdGridInnerContainer">
                        <div className="categoryImage">
                            <img src={env.string +'tooth-extraction.jpg'} alt=""></img>
                        </div>
                        <div className="content">
                            <div className="extractTitle">
                                <img
                                    className="extract_icon"
                                    src="http://clients.technooyster.biz/smilestudio-dev/wp-content/uploads/2019/04/teeth1.png"
                                    alt=""
                                />
                                <span className="extract_text"><strong>Simple Extraction</strong></span>
                            </div>
                            <img
                                className="extract_line"
                                src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                                alt=""
                            />
                            <p>
                                Most teeth which are visible in the mouth can be removed by simple
                                extraction. In this procedure the dentist numbs the area around the tooth with
                                local anaesthesia, loosens the tooth and carefully removes it. It takes a few
                                minutes to complete this treatment.
                    </p>
                        </div>

                    </div>
                </div>
                <div className="cdGridItem"> {/*col-sm-4*/}
                    <div className="cdGridInnerContainer">
                        <div className="categoryImage">
                            <img src={env.string +'teeth_1.jpg'} alt=""></img>
                        </div>
                        <div className="content">
                            <div className="extractTitle">
                                <img
                                    className="extract_icon"
                                    src="http://clients.technooyster.biz/smilestudio-dev/wp-content/uploads/2019/04/teeth1.png"
                                    alt=""
                                />
                                <span className="extract_text"><strong>Surgical Extraction</strong></span>
                            </div>
                            <img
                                className="extract_line"
                                src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                                alt=""
                            />

                            <p>
                                In complex cases Surgical Extractions are done to remove the teeth that are
                                not easily accessible. Such teeth that are difficult to remove must be taken
                        out in pieces. At <Link to="/">iDentist</Link> the surgical removal of teeth is done by our
                                                                                                                            experienced Oral & Maxillofacial Surgeons. A surgical extraction may be
                                                                                                                            needed if:
                    </p>
                            <ul>
                                <li>
                                    Teeth that have not fully erupted or still under the gum line
                        </li>
                                <li>
                                    Teeth with curved roots or unusually large roots
                        </li>
                                <li>
                                    Teeth that have broken off at the gum line.
                        </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="cdGridItem"> {/*col-sm-4*/}
                    <div className="cdGridInnerContainer">
                        <div className="categoryImage">
                            <img src={env.string +'impacted-wisdom-tooth.jpg'} alt=""></img>
                        </div>
                        <div className="content">
                            <div className="extractTitle">
                                <img
                                    className="extract_icon"
                                    src="http://clients.technooyster.biz/smilestudio-dev/wp-content/uploads/2019/04/teeth1.png"
                                    alt=""
                                />
                                <span className="extract_text"><strong>Wisdom Tooth Extraction</strong></span>
                            </div>
                            <img
                                className="extract_line"
                                src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                                alt=""
                            />
                            <p>
                                Wisdom teeth, also called as ‘third molars’, can often cause problems during
                                their eruption (breaking through the gum tissue). They may only erupt
                                partially as there is less space in the jaw. They may be embedded completely
                                in the jaw bone. They exert pressure on other teeth causing pain and hence
                                have to be extracted. Also, they are difficult to clean as they are the last teeth
                                and the tooth brush does not reach there.
                    </p>
                            <p>
                                So if infected it is best to extract them. At iDentist we have a team
                        of <Link to="/AboutUs">experienced oral & maxillofacial surgeons</Link> for such extractions.
                    </p>
                            <p>
                                We have a variety of pain management techniques that make the extraction
                                procedures comfortable and virtually painless. They are completed in single
                                visit and patients heal quickly after the procedure.
                    </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default connect()(SurgicalExtraction);
