import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../index.jsx';
class Specialities extends React.Component {
	constructor(props) {
		super();
		this.state = {};
	}
	componentDidMount() {
		this.props.dispatch(storeStateInfo('Specialities'));
	}
	render() {
		return <div />;
	}
}

export default connect()(Specialities);
