var env = {
    string: '',
    api_url: ''
}
console.log(process.env.NODE_ENV);
if(process.env.NODE_ENV != "production") {
    env.string = "http://localhost:3000/assets/images/"
    env.api_url = "http://localhost:8080/api/"
} else {
    env.string = "http://identistindore.in/assets/images/"
    env.api_url = "http://identistindore.in/api/"
}

console.log(env);

export default env;