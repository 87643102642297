import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../../index.jsx';
import env from '../../js/public'
class RootCanal extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('treatments'));
    }
    render() {
        return (<div>
            <div className="grid-container canal_row">
                <div className="grid-item"> {/*col-sm-4*/}
                    <span className="canal_heading">Root Canal Treatment</span>
                    <img
                        className="new_line"
                        src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                        alt=""
                    />
                    <img src={env.string +'root-canal-therapy.jpg'} alt="" />
                </div>
                <div className="grid-item"> {/*col-sm-8*/}
                    <p>
                        A root canal treatment is needed when there is infection or injury to the central part of the
                        tooth called the pulp. Teeth with diseased or injured
                        pulps which were extracted earlier can now be saved. At <b>iDentist</b>, our
                        experienced Dental Specialists make this procedure comfortable, quick and
                        absolutely painless!
                    </p>
                    <span><strong>Indications for Root Canal Treatment :</strong></span>
                    <ul>
                        <li>
                            Deep cavity or decayed or infected tooth
                        </li>
                        <li>
                            Cracked tooth or broken tooth due to trauma
                        </li>
                        <li>
                            Gum disease
                        </li>
                    </ul>
                    <p>
                        Symptoms can vary from tooth sensitivity to severe throbbing pain and
                        swelling.
                    </p>
                    <p>
                        <b>Steps in a Root Canal Treatment :</b>
                    </p>
                    <p>
                        During Root Canal Treatment the dentist removes the pulp, which is situated
                        in the centre of the tooth. Then the tooth is cleaned thoroughly,
                        filled and sealed. Since the tooth becomes brittle after root canal, a crown or
                        a bridge is placed on it to prevent its fracture.
                    </p>
                    <p>
                        At <b>iDentist</b> we have highly experienced Endodontists i.e. Dentists who
                        specialize in Root canal treatments who can perform the entire procedure in a
                        single visit. A properly done root canal with a cap can last for a lifetime. We
                        use the latest technology, like rotary system, which provides our patients with
                        the best quality at an affordable cost.
                    </p>
                    <img src={env.string +'rct2.png'} alt="" />
                </div>
            </div>
        </div>);
    }
}

export default connect()(RootCanal);
