import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../index.jsx';
import env from '../js/public';
class Credentials extends React.Component {
	constructor(props) {
		super();
		this.state = {};
	}
	componentDidMount() {
		this.props.dispatch(storeStateInfo('Credentials'));
	}
	render() {
		return (<div>
			<h1 className="about_header">Certificates</h1>
			<img className="about_img" src={env.string +'certificates/mds_1.jpg'} alt=""></img>
			<img className="about_img" src={env.string +'certificates/2.jpg'} alt=""></img>
			<img className="about_img" src={env.string +'certificates/3.jpg'} alt=""></img>
			<img className="about_img" src={env.string +'certificates/4.jpg'} alt=""></img>
			<img className="about_img" src={env.string +'certificates/5.jpg'} alt=""></img>
			<img className="about_img_2" src={env.string +'certificates/6.jpg'} alt=""></img>
			<img className="about_img" src={env.string +'certificates/7.jpg'} alt=""></img>
			<img className="about_img" src={env.string +'certificates/8.jpg'} alt=""></img>
			<img className="about_img" src={env.string +'certificates/9.jpg'} alt=""></img>
			<img className="about_img" src={env.string +'certificates/10.jpg'} alt=""></img>
			<img className="about_img" src={env.string +'certificates/11.jpg'} alt=""></img>
			<img className="about_img_3" src={env.string +'certificates/12.jpg'} alt=""></img>
		</div>);
	}
}

export default connect()(Credentials);
