/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { storeStateInfo } from '../index.jsx';
import '../css/home.css';

// import Header from '../common/Header';
// import Footer from '../common/Footer';

class Home extends React.Component {
	constructor(props) {
		super();
		this.state = {};
	}
	componentDidMount() {
		this.props.dispatch(storeStateInfo('/'));
	}
	render() {
		return (
			<div>
				<section id="main-slider" className="no-margin">
					<div id="slider-main" className="carousel slide" data-ride="carousel">
						<ol className="carousel-indicators">
							<li data-target="#slider-main" data-slide-to="0" className="active" />
							<li data-target="#slider-main" data-slide-to="1" />
							<li data-target="#slider-main" data-slide-to="2" />
						</ol>
						<div className="carousel-inner">
							<div
								className="item active"
								style={{ backgroundImage: 'url("./assets/images/slider/dental-1.jpg")' }}
							>
								<div className="container">
									<div className="row">
										<div className="col-md-7">
											<div className="carousel-content">
												<h1 className="animation animated-item-1">Because your smile is our passion</h1>
												<div className="animation animated-item-2">
													We provide you with the best quality in all aspects of dental care in a
													friendly & caring environment
												</div>
												{/* <a className="btn-slide animation animated-item-3" href="#">
													Learn More
												</a>
												<a className="btn-slide white animation animated-item-3" href="#">
													Get Started
												</a> */}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="item" style={{ backgroundImage: 'url("./assets/images/slider/hd images.png")' }}>
								<div className="container">
									<div className="row">
										<div className="col-md-7">
											<div className="carousel-content">
												<h1 className="animation animated-item-1">
													A better life starts with a beautiful smile
												</h1>
												<div className="animation animated-item-2">
													We are here to let you smile beautifully
												</div>
												{/* <a className="btn-slide white animation animated-item-3" href="#">
													Learn More
												</a>
												<a className="btn-slide animation animated-item-3" href="#">
													Get Started
												</a> */}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="item" style={{ backgroundImage: 'url("./assets/images/slider/bright-smile.jpg")' }}>
								<div className="container">
									<div className="row">
										<div className="col-md-7">
											<div className="carousel-content">
												<h1 className="animation animated-item-1">
													Dedicated to excellence Dedicated to your smile
												</h1>
												<div className="animation animated-item-2">
													{/* We provide you with the best quality in all aspects of dental care
													in a friendly & caring environment. */}
												</div>
												{/* <a className="btn-slide white animation animated-item-3" href="#">
													Learn More
												</a>
												<a className="btn-slide animation animated-item-3" href="#">
													Get Started
												</a> */}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="item" style={{ backgroundImage: 'url("./assets/images/slider/upclose-smile.jpeg")' }}>
								<div className="container">
									<div className="row">
										<div className="col-md-7">
											<div className="carousel-content">
												<h1 className="animation animated-item-1">
													Your pathway to a bright new smile
												</h1>
												<div className="animation animated-item-2">
													{/* We provide you with the best quality in all aspects of dental care
													in a friendly & caring environment. */}
												</div>
												{/* <a className="btn-slide white animation animated-item-3" href="#">
													Learn More
												</a>
												<a className="btn-slide animation animated-item-3" href="#">
													Get Started
												</a> */}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="item" style={{ backgroundImage: 'url("./assets/images/slider/teeth-smile-great.jpg")' }}>
								<div className="container">
									<div className="row">
										<div className="col-md-7">
											<div className="carousel-content">
												<h1 className="animation animated-item-1">
													It’s time to smile again
												</h1>
												<div className="animation animated-item-2">
													{/* We provide you with the best quality in all aspects of dental care
													in a friendly & caring environment. */}
												</div>
												{/* <a className="btn-slide white animation animated-item-3" href="#">
													Learn More
												</a>
												<a className="btn-slide animation animated-item-3" href="#">
													Get Started
												</a> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<a className="prev hidden-xs hidden-sm" href="#slider-main" data-slide="prev">
						<FontAwesomeIcon icon={['fa', 'chevron-left']} />
					</a>
					<a className="next hidden-xs hidden-sm" href="#slider-main" data-slide="next">
						<FontAwesomeIcon icon={['fa', 'chevron-right']} />
					</a>
				</section>
				{/* <section id="feature">
					<div className="container">
						<div className="center fadeInDown">
							<h2>Features</h2>
							<p className="lead">
								Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
								incididunt ut <br /> et dolore magna aliqua. Ut enim ad minim veniam
							</p>
						</div>

						<div className="row">
							<div className="features">
								<div
									className="col-md-3 col-sm-4 fadeInDown"
									data-wow-duration="1000ms"
									data-wow-delay="600ms"
								>
									<div className="feature-wrap">
										<div className="icon">
											<FontAwesomeIcon icon={[ 'fa', 'rocket' ]} />
										</div>
										<h2>Fast</h2>
										<p>Having a baby can be a nerve wracking experience for new</p>
									</div>
								</div>
								<div
									className="col-md-3 col-sm-4 fadeInDown"
									data-wow-duration="1000ms"
									data-wow-delay="600ms"
								>
									<div className="feature-wrap">
										<div className="icon">
											<FontAwesomeIcon icon={[ 'fa', 'check' ]} />
										</div>
										<h2>Easy</h2>
										<p>If you are looking for a new way to promote your business that</p>
									</div>
								</div>
								<div
									className="col-md-3 col-sm-4 fadeInDown"
									data-wow-duration="1000ms"
									data-wow-delay="600ms"
								>
									<div className="feature-wrap">
										<div className="icon">
											<FontAwesomeIcon icon={[ 'fa', 'bullhorn' ]} />
										</div>
										<h2>Cheap</h2>
										<p>Okay, you’ve decided you want to make money with Affiliate</p>
									</div>
								</div>
								<div
									className="col-md-3 col-sm-4 fadeInDown"
									data-wow-duration="1000ms"
									data-wow-delay="600ms"
								>
									<div className="feature-wrap">
										<div className="icon">
											<FontAwesomeIcon icon={[ 'fa', 'arrows-alt' ]} />
										</div>
										<h2>Editable</h2>
										<p>A Pocket PC is a handheld computer, which features many</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}

				<section id="recent-works">
					<div className="container">
						<div className="center fadeInDown speciality_div">
							<h2>
								Welcome to <strong style={{ color: '#EC5538' }}>iDentist</strong>
							</h2>
							<img
								className="new-line"
								src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
								alt=""
							/>
							<p className="lead">
								<strong>iDentist</strong> is a modern, hi-tech multi-specialty dental clinic in Indore, India
								headed by <strong>Dr. Ankit Mhaskar</strong> (B.D.S, M.D.S – Oral & Maxillofacial Surgeon
								) & <strong>Dr. Snehal Mhaskar</strong> (B.D.S, M.D.S - Endodontist & Root
								Canal Specialist - Gold Medallist, Mumbai) .It has a team of super specialists
								dedicated towards delivering all <Link to="/treatments/GeneralDentistry">dental treatment</Link> under one roof.
							</p>
							<p>
								We strive to provide high quality, personalized dental care in a relaxing,
								comfortable and safe environment. Lifelong relationships are created with our
								patients based on trust and honesty. Our experienced team is trained to
								handle all dental treatments, from simple dental fillings to complex
								dental procedures.
							</p>
							<p>
								All through your time with <strong>iDentist</strong>, our caring and <Link to="/AboutUs">dedicated team of
								dentists</Link> and staff will provide you with the best service in a warm and
								relaxing ambience. You will be benefited from the latest state of the art
								innovations in dentistry.
							</p>
							<p>
								We take time to genuinely understand our patient’s needs and perspective
								and then provide customized solutions to transform their dental health.
							</p>
							<p>
								Visit <strong>iDentist</strong> to experience life-changing <Link to="/treatments/CosmicDentistry">dentistry at affordable costs</Link> !!
							</p>
						</div>

						<div className="row">
							<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
								<div className="recent-work-wrap">
									<img
										className="img-responsive"
										src={process.env.PUBLIC_URL + 'assets/images/gallery/IMG_9970.jpg'}
										alt=""
									/>
									<div className="overlay">
										<div className="recent-work-inner">
											<a
												className="preview"
												href="./assets/images/gallery/IMG_9970.jpg"
												rel="prettyPhoto"
											>
												<FontAwesomeIcon icon={['fa', 'tooth']} />
											</a>
										</div>
									</div>
								</div>
							</div>

							<div style={{ maxHeight: '100px' }} className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
								<div style={{ maxHeight: '287px' }} className="recent-work-wrap">
									<img className="img-responsive" src="./assets/images/gallery/IMG_9977.jpg" alt="" />
									<div className="overlay">
										<div className="recent-work-inner">
											<a
												className="preview"
												href="./assets/images/gallery/IMG_9977.jpg"
												rel="prettyPhoto"
											>
												<FontAwesomeIcon icon={['fa', 'tooth']} />
											</a>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
								<div className="recent-work-wrap">
									<img className="img-responsive" src="./assets/images/gallery/IMG_9978.jpg" alt="" />
									<div className="overlay">
										<div className="recent-work-inner">
											<a
												className="preview"
												href="./assets/images/gallery/IMG_9978.jpg"
												rel="prettyPhoto"
											>
												<FontAwesomeIcon icon={['fa', 'tooth']} />
											</a>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
								<div className="recent-work-wrap">
									<img className="img-responsive" src="./assets/images/gallery/IMG-20180112-WA0002.jpg" alt="" />
									<div className="overlay">
										<div className="recent-work-inner">
											<a
												className="preview"
												href="./assets/images/gallery/IMG-20180112-WA0002.jpg"
												rel="prettyPhoto"
											>
												<FontAwesomeIcon icon={['fa', 'tooth']} />
											</a>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
								<div className="recent-work-wrap">
									<img className="img-responsive" src="./assets/images/gallery/IMG-20180112-WA0003.jpg" alt="" />
									<div className="overlay">
										<div className="recent-work-inner">
											<a
												className="preview"
												href="./assets/images/gallery/IMG-20180112-WA0003.jpg"
												rel="prettyPhoto"
											>
												<FontAwesomeIcon icon={['fa', 'tooth']} />
											</a>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
								<div className="recent-work-wrap">
									<img className="img-responsive" src="./assets/images/gallery/IMG-20180112-WA0004.jpg" alt="" />
									<div className="overlay">
										<div className="recent-work-inner">
											<a
												className="preview"
												href="./assets/images/gallery/IMG-20180112-WA0004.jpg"
												rel="prettyPhoto"
											>
												<FontAwesomeIcon icon={['fa', 'tooth']} />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="clearfix text-center">
							<br />
							<br />
							{/* <a href="" className="btn btn-primary">
								Show More
							</a> */}
						</div>
					</div>
				</section>

				<section id="services" className="service-item">
					<div className="treatmentsContainer">
						<div className="center fadeInDown">
							<h2>Treatments We Provide</h2>
							{/* <p className="lead">
								Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
								incididunt ut <br /> et dolore magna aliqua. Ut enim ad minim veniam
							</p> */}
						</div>

						<div className="grid-container">
							<div className="grid-item">
								<div className="media services-wrap fadeInDown">
									<div className="pull-left">
										<Link to="/treatments/CosmicDentistry">
											<img className="img-responsive" src="./assets/images/services/ux.svg" alt="" />
										</Link>
									</div>
									<div className="media-body">
										<h3 className="media-heading"><Link to="/treatments/CosmicDentistry">Cosmetic Dentistry</Link></h3>
										<p>
											Increase your sense of confidence and self esteem with Cosmetic
											Dental Surgery and Treatment for Smile makeover!!
										</p>
										<span><Link className="readmore" to="/treatments/CosmicDentistry">Read More | <FontAwesomeIcon icon={['fa', 'angle-right']} /></Link></span>
									</div>
								</div>
							</div>
							<div className="grid-item">
								<div className="media services-wrap fadeInDown">
									<div className="pull-left">
										<Link to="/treatments/SurgicalExtraction"><img className="img-responsive"
											src="./assets/images/services/web.svg" alt="" /></Link>
									</div>
									<div className="media-body">
										<h3 className="media-heading">
											<Link to="/treatments/SurgicalExtraction">Surgical Extraction Of Tooth</Link>
										</h3>
										<p>
											At iDentist, we take every effort to preserve your natural teeth. But there
											are some instances when tooth extraction is the only option. When a tooth
											cannot be saved, it must be professionally removed through a procedure
											called tooth extraction.
										</p>
										<span>
											<Link to="/treatments/SurgicalExtraction">Read More |
                    							<FontAwesomeIcon icon={['fa', 'angle-right']} />
											</Link></span>
									</div>
								</div>
							</div>
							<div className="grid-item">
								<div className="media services-wrap fadeInDown">
									<div className="pull-left">
										<Link to="/treatments/MouthRehab">
											<img className="img-responsive" src="./assets/images/services/motion.svg" alt="" />
										</Link>
									</div>
									<div className="media-body">
										<h3 className="media-heading">
											<Link to="/treatments/MouthRehab">Full Mouth Rehabilitation</Link>
										</h3>
										<p>
											Restore your smile to its full health and beauty.
											There are times when a person’s teeth are severely compromised (decayed teeth
											, gum infection, loss of teeth, worn out teeth, collapsed bite, etc.) and
											they are unable to eat properly which in turn undermine the overall health.
                </p>
										<span>
											<Link to="/treatments/MouthRehab">Read More |
                    <FontAwesomeIcon icon={['fa', 'angle-right']} />
											</Link></span>
									</div>
								</div>
							</div>
							<div className="grid-item">
								<div className="media services-wrap fadeInDown">
									<div className="pull-left">
										<Link to="/treatments/RootCanal">
											<img className="img-responsive" src="./assets/images/services/mobile-ui.svg" alt="" />
										</Link>
									</div>
									<div className="media-body">
										<h3 className="media-heading">
											<Link to="/treatments/RootCanal">Root Canal Treatment</Link>
										</h3>
										<p>
											A root canal treatment is needed when there is infection or injury to the
											central part of the tooth called the pulp. Teeth with diseased or injured
											pulps which were extracted earlier can now be saved. At iDentist, our
											experienced Dental Specialists make this procedure comfortable, quick and
											absolutely painless!
                </p>
										<span>
											<Link to="/treatments/RootCanal">Read More |
                    <FontAwesomeIcon icon={['fa', 'angle-right']} />
											</Link></span>
									</div>
								</div>
							</div>
							<div className="grid-item">
								<div className="media services-wrap fadeInDown">
									<div className="pull-left">
										<Link to="/treatments/SmileDesign">
											<img className="img-responsive" src="./assets/images/services/web-app.svg" alt="" />
										</Link>
									</div>
									<div className="media-body">
										<h3 className="media-heading">
											<Link to="/treatments/SmileDesign">Smile Designing</Link>
										</h3>
										<p>
											A stunning smile is like an instant face lift and an instant mood lift. Improving
											your smile is one of the greatest investments that you can make. A beautiful
											smile instantly enhances your appearance as well as self-confidence.
                </p>
										<span>
											<Link to="/treatments/SmileDesign">Read More |
                    <FontAwesomeIcon icon={['fa', 'angle-right']} />
											</Link></span>
									</div>
								</div>
							</div>
							<div className="grid-item">
								<div className="media services-wrap fadeInDown">
									<div className="pull-left">
										<Link to="/treatments/CompositeVeneers">
											<img className="img-responsive" src="./assets/images/services/mobile-ui.svg" alt="" />
										</Link>
									</div>
									<div className="media-body">
										<h3 className="media-heading">
											<Link to="/treatments/CompositeVeneers">Composite Veneers</Link>
										</h3>
										<p>These are made with tooth coloured composite material and in a single sitting
											change your smile instantly. They are more affordable than
                    ceramic veneers. The entire process can usually be finished in just one visit</p>
										<span>
											<Link to="/treatments/CompositeVeneers">Read More |
                    <FontAwesomeIcon icon={['fa', 'angle-right']} />
											</Link></span>
									</div>
								</div>
							</div>
							<div className="grid-item">
								<div className="media services-wrap fadeInDown">
									<div className="pull-left">
										<Link to="/treatments/GeneralDentistry">
											<img className="img-responsive" src="./assets/images/services/mobile-ui.svg" alt="" />
										</Link>
									</div>
									<div className="media-body">
										<h3 className="media-heading">
											<Link to="/treatments/GeneralDentistry">General Dentistry</Link>
										</h3>
										<p>General Dentistry is the term that refers to basic dental treatments like dental
											cleaning, cavity fillings, root canal treatment, fluoride treatment, extractions,
                    etc. We provide complete dental care for all age.</p>
										<span>
											<Link to="/treatments/GeneralDentistry">Read More |
                    <FontAwesomeIcon icon={['fa', 'angle-right']} />
											</Link></span>
									</div>
								</div>
							</div>
							<div className="grid-item">
								<div className="media services-wrap fadeInDown">
									<div className="pull-left">
										<Link to="/treatments/Crown">
											<img className="img-responsive" src="./assets/images/services/mobile-ui.svg" alt="" />
										</Link>
									</div>
									<div className="media-body">
										<h3 className="media-heading">
											<Link to="/treatments/Crown">Crown & Bridges</Link>
										</h3>
										<p>Missing and damaged teeth are not only unattractive but also cause problems
											in chewing food and speaking. At iDentist, we can help you replace the
                    missing teeth in many ways, dental crowns and bridges are one of them.</p>
										<span>
											<Link to="/treatments/Crown">Read More |
                    <FontAwesomeIcon icon={['fa', 'angle-right']} />
											</Link></span>
									</div>
								</div>
							</div>
							<div className="grid-item">
								<div className="media services-wrap fadeInDown">
									<div className="pull-left">
										<Link to="/treatments/DentalImplants">
											<img className="img-responsive" src="./assets/images/services/mobile-ui.svg" alt="" />
										</Link>
									</div>
									<div className="media-body">
										<h3 className="media-heading">
											<Link to="/treatments/DentalImplants">Dental Implants</Link>
										</h3>
										<p>Dental implants are today’s best replacement alternative to missing teeth in
											terms of form, function, and aesthetics. Implants are now used as routine
											treatment for an entire range of problems from replacing a single tooth to full
                    oral rehabilitation and even for reliable denture retention</p>
										<span>
											<Link to="/treatments/DentalImplants">Read More |
                    <FontAwesomeIcon icon={['fa', 'angle-right']} />
											</Link></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <section id="testimonial">
					<div className="container">
						<div className="center fadeInDown">
							<h2>Testimonials</h2>
							<p className="lead">
								Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
								incididunt ut <br /> et dolore magna aliqua. Ut enim ad minim veniam
							</p>
						</div>
						<div className="testimonial-slider owl-carousel">
							<div className="single-slide">
								<div className="slide-img">
									<img src="./assets/images/client1.jpg" alt="" />
								</div>
								<div className="content">
									<img src="./assets/images/review.png" alt="" />
									<p>
										If you are looking at blank cassettes on the web, you may be very confused at
										the difference in price.
									</p>
									<h4>- Charlotte Daniels</h4>
								</div>
							</div>
							<div className="single-slide">
								<div className="slide-img">
									<img src="./assets/images/client2.jpg" alt="" />
								</div>
								<div className="content">
									<img src="./assets/images/review.png" alt="" />
									<p>
										If you are looking at blank cassettes on the web, you may be very confused at
										the difference in price.
									</p>
									<h4>- Charlotte Daniels</h4>
								</div>
							</div>
							<div className="single-slide">
								<div className="slide-img">
									<img src="./assets/images/client3.jpg" alt="" />
								</div>
								<div className="content">
									<img src="./assets/images/review.png" alt="" />
									<p>
										If you are looking at blank cassettes on the web, you may be very confused at
										the difference in price.
									</p>
									<h4>- Charlotte Daniels</h4>
								</div>
							</div>
							<div className="single-slide">
								<div className="slide-img">
									<img src="./assets/images/client2.jpg" alt="" />
								</div>
								<div className="content">
									<img src="./assets/images/review.png" alt="" />
									<p>
										If you are looking at blank cassettes on the web, you may be very confused at
										the difference in price.
									</p>
									<h4>- Charlotte Daniels</h4>
								</div>
							</div>
							<div className="single-slide">
								<div className="slide-img">
									<img src="./assets/images/client1.jpg" alt="" />
								</div>
								<div className="content">
									<img src="./assets/images/review.png" alt="" />
									<p>
										If you are looking at blank cassettes on the web, you may be very confused at
										the difference in price.
									</p>
									<h4>- Charlotte Daniels</h4>
								</div>
							</div>
							<div className="single-slide">
								<div className="slide-img">
									<img src="./assets/images/client3.jpg" alt="" />
								</div>
								<div className="content">
									<img src="./assets/images/review.png" alt="" />
									<p>
										If you are looking at blank cassettes on the web, you may be very confused at
										the difference in price.
									</p>
									<h4>- Charlotte Daniels</h4>
								</div>
							</div>
						</div>
					</div>
				</section> */}

				{/* <section>
					<div className="container">
						<div className="center fadeInDown">
							<h2>
								{' '}
								<strong style={{ color: '#EC5538' }}>Dental</strong> Tourism
							</h2>
							<img
								className="new-line"
								src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
								alt=""
							/>
							<p className="lead">
								There is a high increase in the number of patients with dental problems who are choosing
								dental tourism to travel countries and find the best dental tourism. In countries like
								UK, Australia, and the USA, dental treatment is very costly, and most of the dental
								treatment plans are not supported under insurance plans. It is one of the main reasons
								why patients seek dental tourism in countries with affordable and effective dental
								services.
							</p>
							<div className="clearfix text-center">
								<a href="" className="btn btn-primary">
									Read More
								</a>
							</div>
							<br />
							<img
								className="new-line"
								src="https://smilestudio.co.in/wp-content/uploads/2019/04/mumbai12-e1556861627828.png"
								alt=""
							/>
						</div>
					</div>
				</section> */}

				{/* <section id="partner">
					<div className="container">
					
					<div className="center fadeInDown" />
						<div className="partners">
							<ul>
								<li>
									<div>
										<span>
											<FontAwesomeIcon icon={[ 'fa', 'user-plus' ]} transform="grow-20" />
											<span>
												<h1>25</h1>
											</span>
										</span>
									</div>
								</li>
								<li />
								<li />
							</ul>
						</div>
					</div>
				</section> */}
			</div>
		);
	}
}
export default connect()(Home);
