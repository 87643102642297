import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../../index.jsx';
import { Link } from 'react-router-dom';
import env from '../../js/public';
class Crown extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('treatments'));
    }
    render() {
        return (<div>
            <div className="row crown_row">
                <div className="col-sm-4">
                    <span className="comp_heading">Crown & Bridges</span>
                    <img
                        className="new_line"
                        src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                        alt=""
                    />
                </div>
                <div className="col-sm-8">
                    <p><strong>Rejuvenate Your Smile with Dental Crowns and Bridges!</strong></p>
                    <p>
                        Missing and damaged teeth are not only unattractive but also cause problems
                        in chewing food and speaking. At <Link to="/">iDentist</Link>, we can help you replace the
                        missing teeth in many ways, dental crowns and bridges are one of them.
                    </p>
                    <p>
                        <b>Dental Crowns</b> also called caps are <Link to="/treatments/MouthRehab">dental restorations</Link> placed over a tooth
                        to cover or encircle a tooth completely, to improve it’s strength and
                        appearance.
                    </p>
                    <p>
                        <b>Dental Bridge</b> covers or bridges the gap created by one or more missing
                        teeth. It takes support from existing teeth or implants to replace a section of
                        missing teeth. A bridge helps to prevent the remaining teeth from moving out
                        of place. Once the bridge is placed, it works just like your natural teeth. It
                        helps with chewing, speaking as well as maintaining the shape of your face. It
                        is durable and strong because it is anchored to your teeth.
                    </p>
                    <span>
                        <strong>
                            Dental Crown/ Caps and Bridges are needed for the following reasons :
                    </strong>
                    </span>
                    <ul>
                        <li>protect a decayed or cracked tooth</li>
                        <li>cover a severely discolored or worn down tooth</li>
                        <li>over a dental implant</li>
                        <li>following root canal treatment</li>
                        <li>make a cosmetic modification</li>
                        <li>cover a large filling where not enough tooth structure is remaining</li>
                    </ul>
                    <p>
                        At <b>iDentist</b> crowns and bridges are made using the latest CAD/CAM
                        (Computerized) technology from the best materials of world-class quality like
                        Zirconia, E Max, Lava, Bruxzir, Tilite, Ultra T and Porcelain fused to metal
                        (PFM).
                    </p>
                    <p>
                        We offer up to lifetime warranty on crown/ bridges at affordable rates.
                    </p>
                    <p>
                        Crowns and Bridges are custom-made and aesthetically pleasing to blend in
                        with your natural teeth. We offer a variety of options to replace missing teeth.
                        Consult our dentists to find out if an implant supported bridge or the
                        conventional bridge is a good option for you
                    </p>
                </div>
                <img src={env.string +'Dental-bridge.png'} alt="" />
                <img src={env.string +'crown and bridge.jpg'} alt="" />
            </div>
        </div>);
    }
}

export default connect()(Crown);
