import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../../index.jsx';
import env from '../../js/public'
class CompositeVeneers extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('treatments'));
    }
    render() {
        return (<div>
            <div className="row comp_row">
                <div className="col-sm-4">
                    <span className="comp_heading">Composite Veneers</span>
                    <img
                        className="new_line"
                        src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                        alt=""
                    />
                    <img src={env.string +'teeth_3.jpg'} alt="" />
                </div>
                <div className="col-sm-8">
                    <p>
                        These are made with tooth coloured composite material and in a single sitting
                        change your smile instantly. They are more affordable than
                        ceramic veneers. The entire process can usually be finished in just one visit.
                    </p>
                    <span className="comp_span"><strong>Process of Smile Makeover</strong></span>
                    <p>At <b>iDentist</b>, our dentists are true designers of a gorgeous smile customized
                    to your face. Patients will have a unique experience right from the start. After
                    conducting a detailed evaluation of your teeth, gums, jaws and face, we will
                    decide a treatment plan based on your individual and unique needs. At first
                    , Digital X-rays and photographs are taken to understand your needs and
                    expectations.</p>
                    <p>
                        The veneers and crowns are manufactured from materials of international
                        quality. The state of the art CAD/CAM (Computerized) technology is used to
                        manufacture them. This results in a natural looking smile which is achieved
                        with ease and comfort.
                    </p>
                </div>
            </div>
        </div>);
    }
}

export default connect()(CompositeVeneers);
