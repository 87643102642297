import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../../index.jsx';
import env from '../../js/public'
class DentalImplants extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('treatments'));
    }
    render() {
        return (<div>
            <div className="grid-container dental_row">
                <div className="grid-item"> {/*col-sm-4*/}
                    <span className="cosmic_heading">Dental Implants</span>
                    <img
                        className="new_line"
                        src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                        alt=""
                    />
                    <p><strong>Single tooth</strong></p>
                    <img src={env.string +'iStock-543979766-2.jpg'} alt="" />
                    <p>A single implant and its crown (top tooth) are placed in the area of the
                       missing tooth.</p>

                    <p><strong>Multiple teeth</strong></p>
                    <img src={env.string +'implant-supported-bridge.jpg'} alt="" />
                    <p>
                        If the missing teeth are in different areas, several single implants with crowns
                        can be placed. If the missing teeth are in the same area, a fewer number of
                        implants with fixed bridges can be done.
                    </p>

                    <p><strong>Full mouth/ all teeth</strong></p>
                    <img src={env.string +'full-arch-dental-implants-zirconia-milled.jpg'} alt="" />
                    <p>
                        If all teeth are missing, full mouth rehabilitation can be done using implants
                        with fixed dentures or bridges.
                    </p>

                </div>
                <div className="grid-item"> {/*col-sm-4*/}
                    <p><strong>Dental Implants in Indore, India</strong></p>

                    <p>Dental implants are today’s best replacement alternative to missing teeth in
                        terms of form, function, and aesthetics. Implants are now used as routine
                        treatment for an entire range of problems from replacing a single tooth to full
                        oral rehabilitation and even for reliable denture retention.</p>

                    <p><b>A dental implant</b> is a titanium screw which is used to replace the root of a
                    missing tooth. After it fuses with the jaw bone, it is used to give support to
                    the tooth crown. Thus a new, artificial tooth is made which improves the
                        smile, speech, and chewing functions. At <b>iDentist</b>, we use only the best
                        dental implant systems from the world over e.g. Nobel Biocare, Bio Horizon,
                        IHDE , gen XT, Osstem ,Equinox, Myraid etc. to suit each patient’s dental
                        requirements.</p>

                    <p>Depending on the technology and time frame involved, implants can be of different types</p>

                    <p><strong>Immediate implants</strong></p>

                    <p>
                        In this procedure, the dental implants are placed at the same time after the
                        teeth are extracted. The top crowns/fixed teeth may or may not be placed in
                        the same trip depending on the bone condition.
                    </p>

                    <p><strong>Single stage or Immediate loaded dental implants</strong></p>

                    <p>
                        Immediate dental implants technology can be used by almost 99% of the
                        patients. This technology provides an immediate solution to tooth loss,
                        whether it is a single tooth missing, few teeth missing, all teeth missing or any
                        number of teeth to be removed and replaced. The salient feature of immediate
                        dental implants is that it ossifies immediately. The restorations (crowns,
                        bridges) are completed within few days time. Restorative options are flexible
                        depending on patient requirements.
                    </p>
                </div>
                <div className="grid-item"> {/*col-sm-4*/}
                    <p>
                        <strong>Basal Implants</strong>
                    </p>

                    <p>
                        With this Swiss-based revolutionary technology, patients with insufficient
                        bone can also easily opt for implant treatment without the need for bone
                        rafts, sinus lift procedures etc. It has made implant treatment possible for
                        one and all. Basal implants are a revolutionary and completely new method
                        for dental implantation, where there is no need for bone augmentation even in
                        the worst cases. And the entire treatment with fixed teeth can be completed
                        within 3 to 5 days.
                    </p>

                    <p>Characteristics of basal implants and screws</p>
                    <ul>
                        <li>
                            Basal implants have a special form that allows their placement even in
                            cases of severe bone atrophy in patients with 'insufficient' bone level
                        </li>
                        <li>
                            Basal implants are placed in one visit thus this method is less
                            traumatic.
                        </li>
                        <li>
                            Basal implants are the first choice for moderate or severe atrophy of
                            the jaw, and in cases where patients want immediate restoration of
                            masticatory function.
                        </li>
                        <li>
                            No substitute bone (bone augmentation) and sinus lift are necessary
                            before placing the implants
                        </li>
                        <li>
                            Basal implants can be placed in smokers and patients with diabetes.
                        </li>
                    </ul>
                </div>
                <div className="grid-item"> {/*col-sm-4*/}

                    <p><strong>Conventional two stage implants</strong></p>

                    <p>
                        Usually two visits of 7 to 10 days each. The implant treatment usually
                        consists of two stages with a time interval of approx 3 months between the
                        two stages. In the First stage, the dental implant is placed. Time needed is
                        one to two sittings. (Total 7 days). In the Second stage, the artificial tooth
                        (crown) is placed. Time needed is three to four sittings. (Total 8-10 days)
                    </p>

                    <p><strong>Advantages and benefits of dental implants :</strong></p>

                    <p><strong>Enhanced appearance</strong></p>

                    <p>
                        Dental implants give new, natural looking teeth thus enhancing the smile
                        , speech, and chewing functions. They reverse the negative impact of missing teeth
                        by preventing bone loss and gum recession
                    </p>

                    <p><strong>Improved self-esteem and confidence</strong></p>

                    <p>
                        Dental implants are very secure. There is no slipping, wobbling or movement. They feel more comfortable and natural compared to removable dentures and
                        bridges.
                    </p>

                    {/* <p><strong>Tooth-saving</strong></p>

                    <p>As there is no need to file down the adjacent teeth, dental implants help to
                       maintain and protect them.</p>

                    <p><strong>Reliability</strong></p>

                    <p>
                        Dental implant treatment has a very good success rate. It is one of the best
                        and long lasting replacements for missing teeth. Good oral hygiene, healthy
                        gums, and adequate bone structure all contribute to implant success.
                    </p> */}

                </div>
                <div className="grid-item"> {/*col-sm-4*/}
                    <p><strong>Tooth-saving</strong></p>

                    <p>As there is no need to file down the adjacent teeth, dental implants help to
                       maintain and protect them.</p>

                    <p><strong>Reliability</strong></p>

                    <p>
                        Dental implant treatment has a very good success rate. It is one of the best
                        and long lasting replacements for missing teeth. Good oral hygiene, healthy
                        gums, and adequate bone structure all contribute to implant success.
                    </p>

                    <p><strong>Can you opt for dental implant treatment?</strong></p>

                    <p>
                        Yes, if you have good general and oral health. Also required is good bone
                        structure, healthy gums and good oral hygiene. Bone grafting may be needed
                        in some cases. Dental implants can be used to replace all teeth, some teeth or
                        even a single tooth.
                    </p>

                    <p><strong>What can you expect after dental implant treatment</strong></p>

                    <p>
                        The placement of the dental implants is a painless procedure carried out
                        under local anaesthesia. Following the surgery, slight pain and/or a minor
                        swelling may be expected. In most cases, simple medication is prescribed for
                        some days. Like natural teeth, dental implants can be easily maintained with
                        regular brushing, flossing and periodic follow-ups with the dentist. Because
                        of the advances in implant dentistry, today there are numerous treatment
                        options possible. A clinical examination, x-rays and CT scan evaluations
                        coupled with patient expectations, budget, time frame etc, enables us
                        to give a customized treatment option to each patient.
                    </p>

                </div>
            </div>
        </div>);
    }
}

export default connect()(DentalImplants);
