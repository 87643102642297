/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './js/font';
import Home from './pages/Home';
import {connect} from 'react-redux';
// import {storeStateInfo} from './index.jsx';
class App extends React.Component {
	constructor(props) {
		super();
		this.state = {};
	}
	componentDidMount() {
	}
	render() {
		return (
			<Home />
		);
	}
}

export default connect()(App);
