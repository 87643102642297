import React from 'react';
import ReactDOM from 'react-dom';

import './css/index.css';
import App from './App';

// pages
import About from './pages/about_us';
import Photo from './pages/photo_gallery';
import Specialities from './pages/our_specialities';
import Testimonial from './pages/Testimonials';
import Contact from './pages/contact_us';
import Credentials from './pages/Credentials';

// common
import Header from './common/Header';
import Bottom from './common/Bottom'

import { createStore } from 'redux';
import { Provider } from 'react-redux';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import './js/main';
import 'jquery/dist/jquery.slim.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'tether/dist/js/tether.js';
import './css/App.css';
import './css/pages/treatments/CosmicDentistry.css';
import './css/pages/treatments/SurgicalExtraction.css';
import './css/pages/treatments/RootCanal.css';
import './css/pages/treatments/CompositeVeneers.css';
import './css/pages/treatments/Crown.css';
import './css/pages/treatments/DentalImplants.css';
import './css/pages/treatments/SmileDesign.css';
import './css/pages/treatments/GeneralDentistry.css';
import './css/pages/treatments/MouthRehab.css';
import './css/core/animate.min.css';
import './css/core/icomoon.css';
import './css/core/owl.carousel.min.css';
import './css/core/prettyPhoto.css';
import './css/core/responsive.css';
import 'font-awesome/css/font-awesome.min.css';
import CosmicDentistry from './pages/treatments/CosmicDentistry';
import SurgicalExtraction from './pages/treatments/SurgicalExtraction';
import MouthRehab from './pages/treatments/MouthRehab';
import RootCanal from './pages/treatments/RootCanal';
import SmileDesign from './pages/treatments/SmileDesign';
import CompositeVeneers from './pages/treatments/CompositeVeneers';
import GeneralDentistry from './pages/treatments/GeneralDentistry';
import Crown from './pages/treatments/Crown';
import DentalImplants from './pages/treatments/DentalImplants';
// import './js/jquery.prettyPhoto';
// import './js/jquery.isotope.min.js';
// import './js/owl.carousel.min.js';

export function storeStateInfo(state_info) {
	return {
		type: 'store_header_info',
		state_info
	};
}
export function stateInfo(state = {}, action) {
	switch (action.type) {
		case 'store_header_info':
			return action;
		default:
			return state;
	}
}

const store = createStore(stateInfo);
ReactDOM.render(
	<Provider store={store}>
		<Router>
			{/* <Switch> */}
			<Header />
			<Route exact path="/" component={App} />
			<Route path="/AboutUs" component={About} />
			<Route path="/SmileGallery" component={Photo} />
			<Route path="/ContactUs" component={Contact} />
			<Route path="/Specialities" component={Specialities} />
			<Route path="/Testimonials" component={Testimonial} />
			<Route path="/Credentials" component={Credentials} />
			{/* treatments route */}
			<Route path="/treatments/CosmicDentistry" component={CosmicDentistry} />
			<Route path="/treatments/SurgicalExtraction" component={SurgicalExtraction} />
			<Route path="/treatments/MouthRehab" component={MouthRehab} />
			<Route path="/treatments/RootCanal" component={RootCanal} />
			<Route path="/treatments/SmileDesign" component={SmileDesign} />
			<Route path="/treatments/CompositeVeneers" component={CompositeVeneers} />
			<Route path="/treatments/GeneralDentistry" component={GeneralDentistry} />
			<Route path="/treatments/Crown" component={Crown} />
			<Route path="/treatments/DentalImplants" component={DentalImplants} />
			<Bottom />
			{/* </Switch> */}
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
