import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../index.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../css/pages/smile_gallery.css'
class Photo extends React.Component {
	constructor(props) {
		super();
		this.state = {};
	}
	componentDidMount() {
		this.props.dispatch(storeStateInfo('PhotoGallery'));
	}
	render() {
		return <>
			<section id="recent-works">
				<div className="container">
					<h1 className="smile_header">
						Welcome to <strong style={{ color: 'skyblue' }}>Smile Gallery</strong>
					</h1>
					<img
						className="new-line smile_img"
						src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
						alt=""
					/>
					<div className="row">
						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img
									className="img-responsive"
									src={process.env.PUBLIC_URL + 'assets/images/gallery/smile/Slide_1.JPG'}
									alt=""
								/>
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide_1.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div style={{ maxHeight: '100px' }} className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div style={{ maxHeight: '287px' }} className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide_2.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide_2.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide_4.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide_4.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide_6.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide_6.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide1.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide1.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide2.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide2.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide3.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide3.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide4.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide4.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide5.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide5.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>
						
						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide6.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide6.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide7.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide7.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide8.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide8.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-4 single-work image_design">
							<div className="recent-work-wrap">
								<img className="img-responsive" src="./assets/images/gallery/smile/Slide9.JPG" alt="" />
								<div className="overlay">
									<div className="recent-work-inner">
										<a
											className="preview"
											href="./assets/images/gallery/smile/Slide9.JPG"
											rel="prettyPhoto"
										>
											<FontAwesomeIcon icon={['fa', 'tooth']} />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="clearfix text-center">
						<br />
						<br />
						{/* <a href="" className="btn btn-primary">
								Show More
							</a> */}
					</div>
				</div>
			</section>
		</>;
	}
}

export default connect()(Photo);
