import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../../index.jsx';
import env from '../../js/public'
class GeneralDentistry extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('treatments'));
    }
    render() {
        return (
            <div>
                <div className="grid-container general_row">
                    <div className="grid-item"> {/*col-sm-4*/}
                        <span className="cosmic_heading">General Dentistry</span>
                        <img
                            className="new_line"
                            src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                            alt=""
                        />
                        <p>
                            It is a procedure to clean the accumulated tartar and stains between the teeth
                            using an ultrasonic vibrator. Scaling keeps your teeth clean and the gums
                            healthy thereby preventing any gum diseases. It is usually a single sitting
                            procedure. Along with proper home care, it is essential to have professional
                            teeth cleaning every six months depending on the necessity. Apart from
                            cleaning, during these visits, our dentists also examine your entire mouth to
                            ensure that your oral health is properly maintained.
                        </p>
                        <p>
                            <strong>Digital X-Rays :</strong>
                        </p>
                        <img src={env.string +'digital-dental-x-ray-1200x488.jpg'} alt="" />
                        <p>
                            A filling is a way to replace the decayed/damaged tooth structure and
                            restoring it back to its normal function and strength.
                        </p>
                        <p>
                            <strong>Filling material:</strong> One does not suit all! The dentist, according to the clinical
                            situation, will choose what’s best for you. Depending on the dentist’s
                            assessment, you will be suggested one of the following materials like Silver
                            or Composite or tooth-colored material.
                        </p>
                        <p>
                            <strong>Children’s Dentistry:</strong> We treat dental problems for children of all ages.
                        </p>
                        <p><strong>Cavity Fillings :</strong></p>
                        <img src={env.string +'filling.jpg'} alt="" />
                        <p><strong>Gum Surgeries</strong></p>
                        <img src={env.string +'pinhole-surgical-technique-2-min.jpg'} alt="" />
                        <p>
                            Gum surgeries are needed for patients who have developed gum disease.
                            Gum disease is one of the most common reasons for tooth loss. This disease
                            starts gradually. Some of the early signs are swollen, red, bleeding gums or
                            bad breath which is followed by more severe symptoms like loose, shifted
                            teeth and a dull ache in the gums. There are many types of gum surgeries that
                            can help alleviate these symptoms. Besides poor oral care routine, there are
                            certain chronic illnesses and certain medications that can lead to gum
                            problems.
                        </p>
                        <p>
                            At <b>iDentist</b>, our Dentists specialized in gum treatment, have received
                            extensive training in gum surgery. We conduct a careful gum examination
                            during each visit. Our goal is to catch gum disease at the earliest stage.
                            Depending on the progression of the disease, we will recommend the therapy
                            that can best help our patient. Our patient’s comfort is our foremost concern.
                        </p>
                        <p>
                            <strong>Treatments available include :</strong>
                        </p>
                        <p>
                            <b>Gum recontouring</b> also called <b>Gingivoplasty:</b> It is a procedure done to
                            shape the gums by cutting off the excess gum tissue. It is done to correct a
                            gummy smile, where a lot of gum tissue is seen when a person smiles.
                            Though it is a normal variation it makes people conscious while smiling and
                            can be corrected, by gum contouring. Gum contouring is done to expose
                            more tooth structure for one or all of the teeth.
                        </p>
                        <p>
                            <b>Gingivectomy</b> and <b>Periodontal surgery</b> are other types of gum surgeries
                            used to treat swollen and diseased gums.
                        </p>
                    </div>
                    <div className="grid-item"> {/*col-sm-8*/}
                        <p>
                            General Dentistry is the term that refers to basic dental treatments like dental
                            cleaning, cavity fillings, root canal treatment, fluoride treatment, extractions,
                            etc. We provide complete dental care for all age.
                        </p>
                        <p>
                            You will be quite comfortable at <b>iDentist</b>, where your dental health is our
                            top priority. Our entire team is dedicated to providing you with high quality,
                            personalized dental care in a comfortable environment.We treat everyone –
                            from children to grandparents. Our dentists have more than 10 years of
                            experience in handling general dental treatments. Prevention and treatment are
                            both parts of General Dentistry. During your visit, you can expect
                            professional oral care as well as advice about self-care at home. Regular
                            dental visits can lead to early diagnosis of oral health issues, giving you an
                            opportunity for early intervention and improved overall health. We offer a
                            vast array of general family dentistry services.
                        </p>
                        <p><strong>Cleaning i.e Scaling & Polishing :</strong></p>
                        <img src={env.string +'teethcleaning-1-300x300.jpg'} alt="" />
                        <p>
                            Through the use of dental x-rays, we are able to diagnose many diseases
                            within the oral cavity. Digital technology is used to capture a very accurate
                            image with the very low dose of radiation.
                        </p>
                        {/* <p><strong>Cavity Fillings :</strong></p>
                        <img src="http://localhost:3000/assets/images/filling.jpg" alt="" /> */}
                        <p><strong>Braces (Orthodontic Treatment):</strong> We provide braces for children and
                        adult, as well as Invisalign, Clear braces and Traditional braces.</p>
                        <img src={env.string +'ASO - Types of Braces - Final-03.png'} alt="" />
                        {/* <p><strong>Gum Surgeries</strong></p>
                        <img src="http://localhost:3000/assets/images/pinhole-surgical-technique-2-min.jpg" alt="" />
                        <p>
                            Gum surgeries are needed for patients who have developed gum disease.
                            Gum disease is one of the most common reasons for tooth loss. This disease
                            starts gradually. Some of the early signs are swollen, red, bleeding gums or
                            bad breath which is followed by more severe symptoms like loose, shifted
                            teeth and a dull ache in the gums. There are many types of gum surgeries that
                            can help alleviate these symptoms. Besides poor oral care routine, there are
                            certain chronic illnesses and certain medications that can lead to gum
                            problems.
                        </p>
                        <p>
                            At <b>iDentist</b>, our Dentists specialized in gum treatment, have received
                            extensive training in gum surgery. We conduct a careful gum examination
                            during each visit. Our goal is to catch gum disease at the earliest stage.
                            Depending on the progression of the disease, we will recommend the therapy
                            that can best help our patient. Our patient’s comfort is our foremost concern.
                        </p>
                        <p>
                            <strong>Treatments available include :</strong>
                        </p>
                        <p>
                            <b>Gum recontouring</b> also called <b>Gingivoplasty:</b> It is a procedure done to
                            shape the gums by cutting off the excess gum tissue. It is done to correct a
                            gummy smile, where a lot of gum tissue is seen when a person smiles.
                            Though it is a normal variation it makes people conscious while smiling and
                            can be corrected, by gum contouring. Gum contouring is done to expose
                            more tooth structure for one or all of the teeth.
                        </p>
                        <p>
                            <b>Gingivectomy</b> and <b>Periodontal surgery</b> are other types of gum surgeries
                            used to treat swollen and diseased gums.
                        </p> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(GeneralDentistry);
