/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
const Bottom = () => {
	return (
		<div>
			<section id="bottom">
				<div className="container fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
					<div className="row">
						{/* <div className="col-md-2">
                    <a href="" className="footer-logo">
                        <img src="images/logo-black.png" alt="logo"/>
                    </a>
                </div> */}
						<div className="col-md-12 footer_main">
							<div className="footer_down">
								<div className="col-md-3 col-sm-6">
									<div className="widget">
										<h4>About Us</h4>
										<img
											className="new-line"
											src="https://smilestudio.co.in/wp-content/uploads/2019/04/new-line.jpg"
											alt=""
										/>
										<div className="footer-content">
											Our highly reputed dental clinic is equipped with cutting-edge dental
											equipment and dental devices to ensure optimum quality and safety.  <Link to="/AboutUs">read more</Link>
										</div>
									</div>
								</div>

								<div className="col-md-3 col-sm-6">
									<div className="widget">
										<h4>Quick Links</h4>
										<img
											className="new-line"
											src="https://smilestudio.co.in/wp-content/uploads/2019/04/new-line.jpg"
											alt=""
										/>
										<ul style={{ margin: '6px' }}>
											<li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'angle-double-right']}
												/>
												<Link to="/">Home</Link>
											</li>
											<li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'angle-double-right']}
												/>
												<Link to="/AboutUs">About Us</Link>
											</li>
											{/* <li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'angle-double-right']}
												/>
												<Link to="/treatments/DentalImplants">Treatments We Provide</Link>
											</li> */}
											{/* <li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'angle-double-right']}
												/>
												<Link to="">Dental Tourism</Link>
											</li> */}
											<li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'angle-double-right']}
												/>
												<Link to="/SmileGallery">Smile Gallery</Link>
											</li>
											<li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'angle-double-right']}
												/>
												<Link to="/Testimonials">Testimonials</Link>
											</li>
											<li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'angle-double-right']}
												/>
												<Link to="/ContactUs">Contact Us</Link>
											</li>
										</ul>
									</div>
								</div>

								<div className="col-md-3 col-sm-6">
									<div className="widget">
										<h4>Get In Touch</h4>
										<img
											className="new-line"
											src="https://smilestudio.co.in/wp-content/uploads/2019/04/new-line.jpg"
											alt=""
										/>
										<ul>
											<li>
												<a href="tel:+9109619791402">
													<FontAwesomeIcon
														className="double_right"
														icon={['fa', 'phone-square']}
													/>{' '}
													+91 96197 91402
													</a>
											</li>
											<li>
												<a href="tel:+919926099797">
													<FontAwesomeIcon
														className="double_right"
														icon={['fa', 'phone-square']}
													/>{' '}
													+91 99260 94777
													</a>
											</li>
											<li>
												<a href="mailto:ankitmhaskar07@yahoo.co.in">
													<FontAwesomeIcon
														className="double_right"
														icon={['fa', 'envelope']}
													/>{' '}
													ankitmhaskar07@yahoo.co.in
													</a>
											</li>
											<li>
												<a href="mailto:snehal_viv@yahoo.co.in">
													<FontAwesomeIcon
														className="double_right"
														icon={['fa', 'envelope']}
													/>{' '}
													snehal_viv@yahoo.co.in
													</a>
											</li>
										</ul>
									</div>
								</div>

								<div className="col-md-3 col-sm-6">
									<div className="widget">
										<h4>Our Location</h4>
										<img
											className="new-line"
											src="https://smilestudio.co.in/wp-content/uploads/2019/04/new-line.jpg"
											alt=""
										/>
										<ul>
											<li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'map-marker']}
												/>
												<strong>307,3rd floor Krishna Tower Above ICICI bank and Bank of Baroda , Opposite Sch No.140, Near, Pipliyahana Rd, Indore, Madhya Pradesh 452016:</strong> {/*(
													<a href="">map</a>) */}
											</li>
											{/* <li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'map-marker']}
												/>
												<strong>Worli:</strong>(
													<a href="">map</a>)
												</li>
											<li>
												<FontAwesomeIcon
													className="double_right"
													icon={['fa', 'map-marker']}
												/>
												<strong>Goregaon West:</strong>(
													<a href="">map</a>)
												</li> */}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<footer id="footer" className="midnight-blue">
				<div className="container">
					<div className="row">
						<div className="col-sm-6">Copyright 2019 @ Identist | All Rights Reserved.</div>
						<div className="col-sm-6">
							{/* <ul className="social-share footer_icons">
								<li>
									<a href="" style={{ color: 'rgb(197, 194, 194)' }}>
										<FontAwesomeIcon icon={['fab', 'facebook']} style={{ color: 'red' }} />
									</a>
								</li>
								<li>
									<a href="">
										<FontAwesomeIcon icon={['fab', 'twitter']} style={{ color: 'red' }} />
									</a>
								</li>
								<li>
									<a href="">
										<FontAwesomeIcon icon={['fab', 'instagram']} style={{ color: 'red' }} />
									</a>
								</li>
							</ul> */}
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Bottom;
