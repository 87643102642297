import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../../index.jsx';

import { Link } from 'react-router-dom';
import '../../css/pages/treatments/treatments.css';
import env from '../../js/public';

class SmileDesign extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('treatments'));
    }
    render() {
        return (
            <div className="smileDesign">
                <div className="row cosmic_row">
                    <div className="col-sm-4 title"> {/*col-sm-4 */}
                        <span className="cosmic_heading">Smile Designing</span>
                        <img className="underline"
                            src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                            alt=""
                        />
                        {/* <img className="img_1" src="http://localhost:3000/assets/images/woman.jpg" alt=""></img> */}
                    </div>
                    <div className="col-sm-8"> {/*col-sm-8*/}
                        <p>
                            A stunning smile is like an instant face lift and an instant mood lift. Improving
                            your smile is one of the greatest investments that you can make. A beautiful
                            smile instantly enhances your appearance as well as self-confidence.
                        </p>
                        <p>
                            As Cosmetic Dentists, we measure up to the challenge of creating a smile that
                            is as unique as you. Creating a beautiful smile is an intimate process. Our
                            Dentists have 10 years of experience in smile design. We design the perfect
                            smile to accent your facial features, hair color, complexion, and personality.
                        </p>
                        <p>
                            This involves a combination of treatments like veneering the teeth, gum
                            contouring, teeth whitening etc. A Smile makeover will transform your teeth
                            as well as improve your self-esteem and improve jaw function and long-
                            lasting results.
                        </p>
                    </div>
                </div>

                <div className="rehab_row">
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'Chipped_Cracked_Broken_Teeth-2.jpg'} alt="" />
                        </div>
                        <div className="link">
                            <span>Chipped or broken teeth</span>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'crooked.jpg'} alt="" />
                        </div>
                        <div className="link">
                            <span>Crooked teeth</span>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'before-after-teeth-whitening.jpg'} alt="" />
                        </div>
                        <div className="link">
                            <span>Discoloured teeth</span>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'gaps.jpg'} alt="" />
                        </div>
                        <div className="link">
                            <span>Gaps and spacing between teeth</span>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'DanielleP_final.jpg'} alt="" />
                        </div>
                        <div className="link">
                            <span>Malaligned teeth or misplaced teeth</span>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img src={env.string +'Prognathism3.png'} alt="" />
                        </div>
                        <div className="link">
                            <span>Protruding teeth</span>
                        </div>
                    </div>
                    <div className="grid-item"> {/*col-sm-4*/}
                        <div className="image">
                            <img className="unstretch" src={env.string +'Gummy-smile-correction-photo.2jpg.jpg'} alt="" />
                        </div>
                        <div className="link">
                            <span>Gummy smile</span>
                        </div>
                    </div>
                </div>

                <div className="gcSmileDesign">
                    <div className="grid-item image"> {/*col-sm-8*/}
                        <img src={env.string +'teeth_compare.jpg'} alt="" />
                    </div>
                    <div className="grid-item content"> {/*col-sm-8*/}
                        <p>
                            You can get an attractive smile in more than one ways :
                        </p>
                        <ul>
                            <li>Porcelain Veneers</li>
                            <li>Composite Veneers(Tooth coloured material)</li>
                            <li>Laser Tooth Whitening</li>
                            <li>Dental Crowns</li>
                            <li>Dental Bridges</li>
                        </ul>
                        <p>
                            Dental Veneers are wafer thin, custom made shells of tooth coloured
                            materials, used to cover the front surface of teeth.
                        </p>
                        <p><strong>Porcelain Veneers :</strong></p>
                        <p>
                            These are made in the dental laboratory and can reconstruct broken, chipped,
                            stained teeth. They are made from materials from US and Europe viz
                            Ceramic, Ultra T (Ultra Translucent) and Emax. Patients love the final result
                            that is a perfect, white and natural smile.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(SmileDesign);
