import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../index.jsx';
import env from '../js/public';
import DateTimePicker from 'react-datetime-picker';
import superAgent from 'superagent';
import '../css/pages/contact_us.css';
class Contact extends React.Component {
    constructor(props) {
        super();
        this.state = {
            date: new Date(),
            Email: "",
            FullName: "",
            PhoneNo: "",
            Message: ""
        };
        this.mimickedState = {
            date: new Date(),
            Email: "",
            FullName: "",
            PhoneNo: "",
            Message: ""
        }
        this.request = superAgent;
        this.addAppointment = this.addAppointment.bind(this);
    }
    addAppointment() {
        this.request.post(env.api_url + 'addAppointment').send(this.state).then(res => {
            console.log(res);
            this.setState(this.mimickedState);
        }).catch(err => {
            if (err) {
                throw new Error(err);
            }
        })
        console.log(this.state);
    }
    componentDidMount() {
        this.props.dispatch(storeStateInfo('ContactUs'))
    }
    render() {
        return (<div>
            <h1 className="contact_header">
                Contact Us
            </h1>
            <img
                className="new-line contact_img"
                src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                alt=""
            />
            <div className="grid">
                <div className="grid-item">
                    <span className="name">Ankit Mhaskar</span>
                    <img
                        className="new-line"
                        src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                        alt=""
                    />
                    <span className="details">Email:</span>
                    <p>ankitmhaskar07@yahoo.co.in</p>
                    <span className="details">Contact Number:</span>
                    <p>+91 96197 91402</p>
                </div>
                <div className="grid-item">
                    <span className="name">Snehal Vivrekar</span>
                    <img
                        className="new-line"
                        src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                        alt=""
                    />
                    <span className="details">Email:</span>
                    <p>snehal_viv@yahoo.co.in</p>
                    <span className="details">Contact Number:</span>
                    <p>+91 99260 94777</p>
                </div>
                <div className="grid-item address">
                    <span className="name">Adresss:</span>
                    <p>
                        307,3rd floor Krishna Tower
                        Above ICICI bank and Bank of Baroda
                        , Opposite Sch No.140, Near,
                        Pipliyahana Rd, Indore, Madhya
                        Pradesh 452016:
                    </p>
                </div>
                {/* <div className="grid-item">
                    <span className="appointment">Get <strong style={{ color: '#EC5538' }}>Appointment</strong></span>
                    <img
                        className="new-line appointment_header"
                        src="https://smilestudio.co.in/wp-content/uploads/2019/04/line.jpg"
                        alt=""
                    />
                    <div className="row">
                        <div className="col-sm-2">
                            <input onChange={(FullName) => this.setState({ FullName: FullName.target.value })} value={this.state.FullName} name="fullName" placeholder="fullName"></input>
                        </div>
                        <div className="col-sm-2">
                            <input onChange={(PhoneNo) => this.setState({ PhoneNo: PhoneNo.target.value })} value={this.state.PhoneNo} name="PhoneNo" placeholder="Phone No."></input>
                        </div>
                        <div className="col-sm-2">
                            <input onChange={(Email) => this.setState({ Email: Email.target.value })} value={this.state.Email} name="Email" placeholder="Email"></input>
                        </div>
                        <div className="col-sm-2">
                            <DateTimePicker onChange={(date) => this.setState({ date })} value={this.state.date} disableClock={true}></DateTimePicker>
                        </div>
                        <div className="col-sm-2">
                            <textarea onChange={(Message) => this.setState({ Message: Message.target.value })} value={this.state.Message} name="Message" placeholder="Message"></textarea>
                        </div>
                    </div>
                    <button onClick={this.addAppointment}>Submit</button>
                </div> */}
            </div>
        </div>);
    }
}

export default connect()(Contact);