import React from 'react';
import { connect } from 'react-redux';
import { storeStateInfo } from '../index.jsx';
import env from '../js/public';
class About extends React.Component {
	constructor(props) {
		super();
		this.state = {};
	}
	componentDidMount() {
		this.props.dispatch(storeStateInfo('AboutUs'));
	}
	render() {
		return (<>
			<h1 className="about_header">About Us</h1>
		</>);
	}
}

export default connect()(About);
