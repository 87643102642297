import {
    library
} from '@fortawesome/fontawesome-svg-core';
import {
    faPlay,
    faPlus,
    faArrowsAlt,
    faBullhorn,
    faCheck,
    faRocket,
    faChevronRight,
    faChevronLeft,
    faAngleDown,
    faSearch,
    faPhoneSquare,
    faEnvelope,
    faUser,
    faUserPlus,
    faAngleDoubleRight,
    faAngleRight,
    faTooth,
    faMapMarker
} from "@fortawesome/free-solid-svg-icons";

import {
    faFacebook,
    faDribbble,
    faSkype,
    faLinkedin,
    faTwitter,
    faInstagram
} from "@fortawesome/free-brands-svg-icons"
library.add(
    faPlay,
    faPlus,
    faTooth,
    faArrowsAlt,
    faBullhorn,
    faCheck,
    faRocket,
    faChevronRight,
    faChevronLeft,
    faAngleDown,
    faSearch,
    faPhoneSquare,
    faFacebook,
    faDribbble,
    faSkype,
    faLinkedin,
    faTwitter,
    faInstagram,
    faEnvelope,
    faUser,
    faAngleDoubleRight,
    faMapMarker,
    faAngleRight,
    faUserPlus
)